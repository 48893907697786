import React, { useCallback, useRef, useState } from "react";

import { Popper } from "../../../Popper";

import { StyledCarousel } from "../ItemDetailsModal/components/ItemDetailsHeader/style";

import { ImagePreview } from "./style";
import { ImageFlyout } from "./ImageFlyout";

export const ImageWithZoomCarousel = ({
    id,
    src,
    popupSize,
    placement = "right",
    currentPhotos,
    marginTop,
    width,
}) => {
    const [popperVisibility, setPopperVisibility] = useState(false);
    const imageRef = useRef();

    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, []);

    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, []);

    return (
        <>
            <ImagePreview ref={imageRef} src={src} onClick={openPopper} />
            {src!=='/product-dot.svg' &&
            <Popper
                id={id}
                visibility={popperVisibility}
                anchorEl={imageRef?.current}
                onClickAway={closePopper}
                placement={placement}
                disablePortal={false}
                style={{ marginTop, marginLeft: "50px", zIndex: 9999, width }}
            >
                <StyledCarousel
                    indicators={false}
                    autoPlay={false}
                    animation="slide"
                    navButtonsAlwaysVisible
                    navButtonsAlwaysInvisible={currentPhotos.length <= 1}
                >
                    {Boolean(currentPhotos.length) &&
                        currentPhotos.map(({ url }) => (
                            <ImageFlyout
                                src={url && `${url}`}
                                size={popupSize}
                                key={url}
                            />
                        ))}
                </StyledCarousel>
            </Popper>}
        </>
    );
};
