import React, { useState, useMemo, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImageMapper from "react-image-mapper";

import {
    useMediaQuery,
    useTheme as useThemeMaterial,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Header from "../../components/Header";
import Drawer from "../../components/Drawer";
import Wrapper from "../../components/Wrapper";
import { Title } from "../../components/Listing/style";
import BreadCrumbs from "../../components/BreadCrumbs";
import {
    fetchOemVehiclesDetails,
    fetchYqOemVehicleDetails,
    fetchPlateVehiclesDetails
} from "../../core/redux/asyncActions/global";

import Order from "./components/Order";
import {
    ProductImage,
    OrderList,
    WrapperItem,
    ContainerItem,
    ContentWrapper,
} from "./style";
import { ItemDetailsModal } from "../../components/Listing/components/ItemDetailsModal";
import TourPopup from "../../components/Tour/TourPopup";
import { StyledCircularProgress } from "../../components/CircularProgress";

const OemItem = () => {
    const { t } = useTranslation();
    const { vin = "", unitId = "", catalog = "", plate = "" } = useParams();
    const themeMaterial = useThemeMaterial();
    const dispatch = useDispatch();
    const [imageMapRef, setImageMapRef] = useState();

    const isMdScreen = useMediaQuery(themeMaterial.breakpoints.down("md"));
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));

    const mobileSizeImg = isMobileScreen ? 300 : 250;
    const imageWidth = isMdScreen ? mobileSizeImg : 400;

    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData
    );

    const { oemVehicles, lang } = useSelector(
        (state) => state.global
    );
    const [detailsList, setDetailsList] = useState([]);
    const [detailsCodes, setDetailsCode] = useState([]);

    const {
        details = [],
        image,
        isLoading = false,
        imagemap = [],
    } = useSelector((state) => state.global?.currentOemVehicles || {});

    const currentProduct = useSelector(
        (state) => state.global?.currentProduct || null
    );

    const onMouseClickPhoto = (area) => {
        setDetailsList((prevState) => {
            const updatedArea = prevState.filter(
                (item) => item.name !== area.name
            );
            const isItemInList =
                prevState.length && updatedArea.length !== prevState.length;

            const filteredAreas = isItemInList
                ? updatedArea
                : [...prevState, area];
            setDetailsCode(filteredAreas.map((item) => item.code));

            return filteredAreas;
        });
    };

    const filteredDetails = useMemo(
        () =>
            details.filter(
                (item) =>
                    !detailsCodes.length ||
                    detailsCodes.includes(item.codeonimage)
            ),
        [details, detailsCodes]
    );

    const onDeleteChip = (code) => {
        setDetailsCode((prevState) =>
            prevState.filter((item) => !item.includes(code))
        );
        setDetailsList((prevState) =>
            prevState.filter((item) => item.code !== code)
        );
    };

    useEffect(() => {
        if (vin && unitId && !oemVehicles?.vehicle?.ssd?.value && !catalog) {
            dispatch(fetchOemVehiclesDetails(unitId, vin));
        } else {
            if (catalog) {
                dispatch(fetchYqOemVehicleDetails(catalog, oemVehicles?.vehicle?.ssd?.value, vin, unitId));
            }
            else {
                dispatch(fetchPlateVehiclesDetails(plate.substring(0, 2), plate.substring(2), unitId));
            }
        }
    }, [unitId,
        vin,
        catalog,
        oemVehicles?.vehicle?.ssd?.value,
        lang,
        plate,
        dispatch]);

    return (
        <>
            {currentProduct &&
                <ItemDetailsModal />}
            <Header />
            <Wrapper>
                <Drawer />
                <WrapperItem>
                    <ContainerItem>
                        <Title>{t("productsList.productCatalog")}</Title>
                        <Box marginBottom="24px">
                            <BreadCrumbs
                                searchValueTitle={vin || plate}
                                itemId={unitId}
                                isMobileScreen={isMobileScreen}
                                detailsCodes={detailsCodes}
                                onDeleteChip={onDeleteChip}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                        >
                            {isLoading ? (
                                <StyledCircularProgress disableShrink/>
                            ) : (
                                <ContentWrapper width="100%">
                                    <ProductImage>
                                        <Box
                                            ref={ref => setImageMapRef(ref)}
                                            position="relative"
                                            height="fit-content"
                                            width="fit-content"
                                        >
                                            <ImageMapper
                                                width={imageWidth}
                                                imgWidth={528}
                                                map={{
                                                    name: "details-map",
                                                    areas: imagemap,
                                                }}
                                                // onMouseLeave={onMouseLeave}
                                                onClick={onMouseClickPhoto}
                                                src={
                                                    image ||
                                                    process.env.PUBLIC_URL +
                                                    "/product-dot.svg"
                                                }
                                            />
                                            {Boolean(detailsList.length) &&
                                                detailsList.map((listItem) => (
                                                    <Box
                                                        className="tooltip"
                                                        style={{
                                                            top: `${listItem.center[1]}px`,
                                                            left: `${listItem.center[0]}px`,
                                                        }}
                                                    >
                                                        {listItem?.code || ""}
                                                    </Box>
                                                ))}
                                        </Box>
                                    </ProductImage>
                                    {imageMapRef && (
                                        <TourPopup
                                            step={16}
                                            delay={200}
                                            anchorEl={imageMapRef}
                                            placement={isMobileScreen ? "bottom-middle" : "right"}
                                        >
                                            {t('appTour.pictureNumbers')}
                                        </TourPopup>
                                    )}
                                    <OrderList>
                                        {Boolean(filteredDetails.length) &&
                                            filteredDetails.map(
                                                ({
                                                    codeonimage = "",
                                                    name = "",
                                                    oem = "",
                                                    items = 0,
                                                }, index) => (
                                                    <Order
                                                        showTourTooltip={index === 0}
                                                        key={oem + codeonimage}
                                                        number={codeonimage}
                                                        orderName={name}
                                                        oem={oem}
                                                        items={items}
                                                        hoveredDetails
                                                        isactive={detailsCodes.find(
                                                            (item) =>
                                                                item ===
                                                                codeonimage
                                                        )}
                                                        currentCountry={
                                                            currentCountry
                                                        }
                                                        isMobileScreen={
                                                            isMobileScreen
                                                        }
                                                    />
                                                )
                                            )}
                                    </OrderList>
                                </ContentWrapper>
                            )}
                        </Box>
                    </ContainerItem>
                </WrapperItem>
            </Wrapper>
        </>
    );
};

export default memo(OemItem);
