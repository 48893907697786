import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification } from "../../core/redux/actions/global";
import { StyledSnackBar } from "./style";
import { useTranslation } from "react-i18next";

export const Notification = ({ timeout = 2 }) => {
    const { t } = useTranslation();
    const { notificationMessage } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = (_, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(deleteNotification());
    };

    const {
        titleCard,
        itemId,
        actionName,
        itemName,
        tagTitle
    } = notificationMessage

    const onClickItem = () => {
        const basket = document.getElementById("basketCard")
        basket && basket.click()
    }

    let message;
    const itemIdLayout = <div>{itemName} {" "}<span onClick={onClickItem} style={{ color: "orange", cursor: "pointer" }}> {itemId} </span></div>
    switch (actionName) {
        case "deletedCart": {
            message = <>{t("notification.actionName.cartDeleted")} {titleCard}</>;
            break;
        }
        case "emptiedCart": {
            message = <>{t("notification.actionName.cartEmptied")} {titleCard}</>;
            break;
        }
        case "setDefaultCart": {
            message = <>{t("notification.actionName.cartDefaulted")} {titleCard}</>;
            break;
        }
        case "removeDefaultCart": {
            message = <>{t("notification.actionName.cartUndefaulted")} {titleCard}</>;
            break;
        }
        case "removed": {
            message = <>{t("notification.itemHasBeen")} {t("notification.actionName.removed")} {titleCard} {itemIdLayout}</>;
            break;
        }
        case "added": {
            message = <>{t("notification.itemHasBeen")} {t("notification.actionName.added")} {titleCard}  {itemIdLayout}</>;
            break;
        }
        case "removedFav": {
            message = <>{t("notification.itemHasBeen")} {t("notification.actionName.removedFav")} {itemIdLayout}</>;
            break;
        }
        case "addedFav": {
            message = <>{t("notification.itemHasBeen")} {t("notification.actionName.addedFav")} {itemIdLayout}</>
            break;
        }
        case "tooManyFav": {
            message = <>{t("notification.actionName.tooManyFav")}</>
            break;
        }
        case "removedAvailNotification": {
            message = <>{t("notification.itemHasBeen")} {t("notification.actionName.removedAvailNotification")} {itemIdLayout}</>;
            break;
        }
        case "addedAvailNotification": {
            message = <>{t("notification.itemHasBeen")} {t("notification.actionName.addedAvailNotification")} {itemIdLayout}</>
            break;
        }
        case "setTagItem": {
            message = <> {t("notification.itemHasBeen")} {t("notification.actionName.addedTag")} {tagTitle} {itemIdLayout}</>
            break;
        }
        case "deleteTag": {
            message = <>{t("notification.actionName.tagDeleted")} {tagTitle}</>
            break;
        }
        case "commentSet": {
            message = <>{t("notification.actionName.commentSet")} {itemIdLayout}</>
            break;
        }
        case "commentRemoved": {
            message = <>{t("notification.actionName.commentRemoved")} {itemIdLayout}</>
            break;
        }
        case "commentExists": {
            message = <>{t("notification.actionName.commentExists")} {itemIdLayout}</>
            break;
        }
        case "removeTagItem": {
            message = <>{t("notification.actionName.theTag")} {tagTitle} {t("notification.actionName.removedFrom")} {itemIdLayout}</>
        }
        default:
            break;
    }

    return (
        !!notificationMessage && (
            <StyledSnackBar
                isphone={isPhoneScreen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                style={{
                    padding: "10px 25px",
                    borderRadius: 10,
                }}
                open={!!notificationMessage}
                autoHideDuration={timeout * 1500}
                onClose={handleClose}
                message={typeof notificationMessage === 'string' ? notificationMessage : message}
            />
        )
    );
};

export default Notification;
