import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import CartsItem from "./item";
import {
    CartInfoSection,
    CartInfoSectionLine,
    CartInfoText,
    CartListWrapper,
    Container,
} from "./style";
import {
    addDefaultBasket,
    deleteDefaultBasket,
    deleteBasket,
    emptyBasket
} from "../../../core/redux/asyncActions/carts";
import Button from "../../Button";
import ConfirmationPopup from "../../ConfirmationPopup";

export default function CartsList({ data = {}, closePopper }) {
    const { t } = useTranslation();
    const defaultCart = useSelector((state) => state.carts.defaultBasket);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    const history = useHistory();

    const [isConfirmEmptyPopupOpen, setIsConfirmEmptyPopupOpen] = useState(false);
    const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState(false);

    const handleDefaultCartButton = useCallback((e) => {
        e.stopPropagation()
        if (defaultCart !== data?.id) {
            dispatch(addDefaultBasket(data?.id, data?.title));
        } else {
            dispatch(deleteDefaultBasket(data?.id, data?.title))
        }
    }, [defaultCart, data.id, dispatch]);

    const handleDeleteCartButton = useCallback((e) => {
        e.stopPropagation()
        setIsConfirmEmptyPopupOpen(false);
        setIsConfirmDeletePopupOpen(true);
    }, []);

    const handleEmptyCartButton = useCallback((e) => {
        e.stopPropagation()
        setIsConfirmDeletePopupOpen(false);
        setIsConfirmEmptyPopupOpen(true);
    }, []);

    const handleConfirmEmptyCartButton = useCallback(() => {
        dispatch(emptyBasket(data?.id, data?.title));
        setIsConfirmEmptyPopupOpen(false);
    }, [data?.id, dispatch]);

    const handleConfirmDeleteCartButton = useCallback(() => {
        dispatch(deleteBasket(data?.id, data?.title));
        setIsConfirmDeletePopupOpen(false);
    }, [data?.id, dispatch]);

    const handleCancelEmptyCartButton = useCallback(() => {
        setIsConfirmEmptyPopupOpen(false);
    }, []);

    const handleCancelDeleteCartButton = useCallback(() => {
        setIsConfirmDeletePopupOpen(false);
    }, []);

    const toOrderPage = () => {
        history.push(`/order/${data.id}`);
        closePopper();
    };

    return (
        <Container>
            <CartListWrapper>
                {data?.items?.map((item, index) => (
                    <CartsItem
                        key={item.cart_item_id}
                        data={item}
                        isFirst={index === 0}
                    />
                ))}
            </CartListWrapper>
            <CartInfoSection>
                <CartInfoSectionLine>
                    <CartInfoText>{t("productsList.cartInfoSection.totalWithoutVAT")}</CartInfoText>
                    <CartInfoText>
                        {data.formatted_total_without_vat}
                    </CartInfoText>
                </CartInfoSectionLine>
                <CartInfoSectionLine>
                    <CartInfoText>{t("productsList.cartInfoSection.VAT")}</CartInfoText>
                    <CartInfoText>{data.formatted_vat}</CartInfoText>
                </CartInfoSectionLine>
                <CartInfoSectionLine>
                    <CartInfoText>
                        <strong>{t("productsList.cartInfoSection.total")}</strong>
                    </CartInfoText>
                    <CartInfoText>
                        <strong>{data.formatted_total}</strong>
                    </CartInfoText>
                </CartInfoSectionLine>
            </CartInfoSection>
                   
            <Grid container spacing={1} style={isMobileScreen ? { padding: "15px" } : { padding: "20px" }}>
                <Grid item xs={12} style={{ height: "60px" }}>
                    <Button disabled={!data?.items?.length} buttonVariant="Filled" onClick={toOrderPage}>
                        {t("productsList.order").toUpperCase()}
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ height: "52px", marginTop: "5px" }}>
                    <Button disabled={!data?.id} onClick={handleDefaultCartButton} buttonVariant="Light">
                        {defaultCart !== data.id
                            ? t("header.setAsDefaultCart").toUpperCase()
                            : t("header.removeDefaultCart").toUpperCase()}
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ height: "52px" }}>
                    <Button disabled={!data?.id} buttonVariant="Light" onClick={handleEmptyCartButton}>
                        {t("header.emptyCart").toUpperCase()}
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ height: "52px" }}>
                    <Button disabled={!data?.id} buttonVariant="Light" onClick={handleDeleteCartButton}>
                        {t("header.deleteCart").toUpperCase()}
                    </Button>
                </Grid>
            </Grid>
            {isConfirmEmptyPopupOpen && (
                <ConfirmationPopup
                    handleConfirmEmptyCartButton={handleConfirmEmptyCartButton}
                    handleCancelEmptyCartButton={handleCancelEmptyCartButton}
                    popupText={t("header.emptyCartMessage")}
                />
            )}
            {isConfirmDeletePopupOpen && (
                <ConfirmationPopup
                    handleConfirmEmptyCartButton={handleConfirmDeleteCartButton}
                    handleCancelEmptyCartButton={handleCancelDeleteCartButton}
                    popupText={t("header.deleteCartMessage")}
                />
            )}
        </Container>
    );
}
