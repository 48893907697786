const resources = {
    login: {
        login: "/token",
        refreshToken: "/token/refresh",
    },
    passwordReset: {
        sendResetLink: "/password-reset",
        validateResetLink: "/password-reset-check",
        changePassword: "/password-reset-submit",
    },
    register: "/register",
    global: {
        userData: "/userinfo",
        menuData: "menu?language=",
        country: "/country",
    },
    vendors: "/vendors",
    products: {
        list: "/items-list",
        publicList: "/public-items-list",
        prices: "/prices",
        details: "/item-details",
        vehicles: "/item-linked-vehicles",
        purchaseHistory: "/item-purchase-history",
    },
    basket: {
        list: '/basket-list',
        add_item: '/basket-update',
        info: '/basket',
        drop: 'basket-drop'
    },
    order: {
        info: 'order-info',
        submit: 'order-submit'
    },
    favorite: '/favorite-item',
    availabilityNotifications: '/notify-item',
    tags: {
        list: '/tag-list',
        itemTagList: '/tagitem-list',
        create: '/tag-create',
        drop: '/tag-drop',
        itemDrop: '/tagitem-drop',
    },
    comments: {
        create: '/itemcomment',
        drop: '/itemcomment-drop',
    },
    oemVehicles: "/oem-vehicle",
    returnItem: "/return-item",
    vefiryAKCode: "/verify-ak-code",
};

export default resources;
