import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Chip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { COUNTRY_CURRENCY } from "../../../../../../core/utils";
import { Separator, SeparatorDirection } from "../../../../../Separator";
import Typography from "../../../../../Typography";

import { CartButtonSizes } from "../../../CartButton";
import { Price } from "../../../Price";
import { Stock } from "../../../Stock";

import { MainBox, StyledCarousel, Trail } from "./style";
import { useTranslation } from "react-i18next";
import { ImageWithZoomCarousel } from "../../../ImageWithZoomCarousel";
import { ImageWithZoom } from "../../../ImageWithZoom";
import QuantityButton from "../../../QuantityButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import {
    clearProductDetails,
    setSelectedItemCategory,
    setSelectedItemGroup,
    setSelectedItemModel,
    setSearchQuery
} from "../../../../../../core/redux/actions/global";
import { 
    fetchProductDetails,
    removeTagItem,
    removeComment
} from "../../../../../../core/redux/asyncActions/global";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { HOME_PATH } from "../../../../../../core/routes";
import api from "../../../../../../core/api";
import { StockDot } from "../../../Stock/style";
import { MiniMessage } from "../../../MiniChangeMessage/MiniMessage";
import { Overlay } from "../../../MiniChangeMessage/style";
import { StyledCircularProgress } from "../../../../../CircularProgress";
import { updateFormSubmissionStatus } from "../../../../../../core/redux/actions/global";
import { MiniInput } from "./MiniInput";
import { MiniButton } from "./MiniButton";
import { SelectedCategoriesWrapper } from "../../../../style";


export const ItemDetailsHeader = ({ data, setIsOverlayVisible }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const notLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

    const [productUnits, setProductUnits] = useState(1);
    const [showComment, setShowComment] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [MiniChangeMessage, setMiniChangeMessage] = useState("");
    const [showMiniMessage, setShowMiniMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    const productUnitsCount = (unitsCount) => {
        setProductUnits(unitsCount);
    }

    const {
        id,
        description: title,
        ak_code: akCode,
        oem_code: oem,
        stock,
        prices,
        comment,
        category,
        group,
        model,
        tags
    } = data;

    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData
    );

    const images = useSelector(
        (state) => state.global?.productDetails?.list?.images || []
    );

    const deleteComment = () => {
        dispatch(removeComment(id, title));
        setShowComment(false);
    }

    useEffect(() => {
        if (comment) {
            setShowComment(true);
        }
        if (tags.length > 0) {
            setShowTags(true);
        }
    }, [comment, tags]);

    const handleCategory = () => {
        dispatch(setSearchQuery(""));
        dispatch(clearProductDetails());
        history.push(HOME_PATH);
        dispatch(setSelectedItemCategory(category));
    }

    const handleGroup = () => {
        handleCategory();
        dispatch(setSelectedItemGroup(group));
    }

    const handleModel = () => {
        handleGroup();
        dispatch(setSelectedItemModel(model));
    }

    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (event) => {
        const { value } = event.target;
        const regex = /^\d*\.?\d*$/; // Positive integer or positive decimal regex pattern

        if (regex.test(value) || value === "") {
        setInputValue(value);
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const minQuantity = event.target.elements.minQuantityValue.value;
        try {
            const body = new FormData();
            body.append("min_quantity", minQuantity);
            body.append("ak_code", akCode)
            const response = await api.post("/item-details", body, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            });

            const status = response.data.status
            const message = response.data.message
            if (response.data && status === "success") {
                setFormSuccess(true);
                setMiniChangeMessage(message)
                setShowMiniMessage(true);
                setIsOverlayVisible(true);
                dispatch(updateFormSubmissionStatus(true));
            } else if (response.data && status === "partial success") {
                setFormSuccess(true);
                setMiniChangeMessage(message)
                setShowMiniMessage(true);
                setIsOverlayVisible(true);
                dispatch(updateFormSubmissionStatus(true));
            } else if (response.data && status === "failure") {
                setFormSuccess(false);
                setMiniChangeMessage(message);
                setShowMiniMessage(true);
                setIsOverlayVisible(true);
            } else {
                console.error("Unexpected response");
            }
        } catch (error) {
            console.error('Error submitting min quantity change form:', error);
        } finally {
            setLoading(false);
        }
    };

    const salesperson = useSelector(
        (state) => state.global?.productDetails?.list?.salesperson
    );

    const minq = useSelector(
        (state) => state.global?.productDetails?.list?.min_quantity
    );

    const handleClickDelete = async (tag) => {
        if (tags.length === 1) {
            setShowTags(false);
        }
        setLoading(true);
        dispatch(removeTagItem(tag[0], tag[1], id, title));
        await dispatch(fetchProductDetails(id, 1));
        setLoading(false);
    };

    return (
        <MainBox
            display="flex"
            flexDirection="row"
            width="100%"
        >
            <Box
                display="flex"
                width="30%"
                justifyContent="center"
                alignItems="center"
                padding="2px"
                overflow="hidden"
            >
                <Box width="175px">
                    {Boolean(images.length) ? (
                        <StyledCarousel
                            indicators={false}
                            autoPlay={false}
                            navButtonsAlwaysVisible
                            navButtonsAlwaysInvisible={images.length <= 1}
                        >
                            {images.map(({ url }) => (
                                <ImageWithZoomCarousel
                                    key={url}
                                    src={url && `${url}`}
                                    currentPhotos={images}
                                    popupSize={{
                                        width: "500px",
                                        height: "500px",
                                    }}
                                />
                            ))}
                        </StyledCarousel>
                    ) :
                        <ImageWithZoom
                            src={"/product-dot.svg"}
                            imgSize={{ imgWidth: "175px", imgHeight: "30px" }}
                        />
                    }
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                padding="0 20px 0 0"
                width="85%"
            >
                {category?.cid &&
                    <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexFlow: "row wrap" }}>
                        <Trail onClick={handleCategory}>
                            {category?.title}
                        </Trail>
                        {group?.gid &&
                            <>
                                <ArrowForwardIosIcon style={{ width: "8px", margin: "0 4px" }} />
                                <Trail onClick={handleGroup}>
                                    {group?.title}
                                </Trail>
                                {model?.mid &&
                                    <>
                                        <ArrowForwardIosIcon style={{ width: "8px", margin: "0 4px" }} />
                                        <Trail onClick={handleModel}>
                                            {model?.title}
                                        </Trail>
                                    </>}
                            </>}
                    </Box>}
                <Box
                    padding="32px 5px 5px 0px"
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Typography
                        color="#37474F"
                        customVariant="heading"
                        fontWeight={500}
                    >
                        {title}
                    </Typography>
                    <Box display="flex" flexDirection="row">
                        <Typography
                            color="#37474F"
                            customVariant="body"
                            fontWeight={700}
                            style={{ fontSize: '32px' }}
                        >
                            {COUNTRY_CURRENCY[currentCountry]}
                        </Typography>
                        <Price price={prices} productUnits={productUnits} fontSize="32px" />
                    </Box>
                </Box>
                <Separator
                    colorBorder={"#37474F"}
                    direction={SeparatorDirection.Horizontal}
                />
                <Box
                    padding="5px 0"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignContent="space-between"
                    >
                        <Box display="flex" padding="10px 0">
                            <Typography
                                customVariant="body"
                                texttransform="uppercase"
                                fontWeight={400}
                            >
                                {t("productsList.tableHeader.AKCode")}:&nbsp;
                            </Typography>
                            <Typography customVariant="body" fontWeight={400}>
                                {akCode}
                            </Typography>
                        </Box>
                        <Box display="flex" padding="10px 0">
                            <Typography
                                customVariant="body"
                                texttransform="uppercase"
                                fontWeight={400}
                            >
                                {t("productsList.tableHeader.oem")}:&nbsp;
                            </Typography>
                            <Typography customVariant="body" fontWeight={400}>
                                {oem}
                            </Typography>
                        </Box>
                        {notLargeScreen && !isLargeScreen && salesperson && (
                            <form onSubmit={handleFormSubmit}>
                                <Box display="flex" padding="10px 0">
                                    <Typography
                                        customVariant="body"
                                        texttransform="uppercase"
                                        fontWeight={400}
                                    >
                                        MINI:&nbsp;
                                    </Typography>
                                    <MiniInput
                                        inputValue={inputValue}
                                        minq={minq}
                                        handleInputChange={handleInputChange}
                                    />
                                    <MiniButton
                                        inputValue={inputValue}
                                        buttonText="CHANGE MINI"
                                    />
                                </Box>
                            </form>
                        )}
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignContent="space-between"
                    >
                        {stock &&
                            <Box display="flex" padding="10px 0">
                                <Typography
                                    customVariant="body"
                                    texttransform="uppercase"
                                    fontWeight={400}
                                >
                                    {t("productsList.tableHeader.stock")}:&nbsp;
                                </Typography>
                                <Stock stock={stock} id={id} marginTop={20}>
                                    {(value, mandatoryProps, dot) => (
                                        <Typography
                                            {...mandatoryProps}
                                            color="#37474F"
                                            customVariant="body"
                                            fontWeight={400}
                                            cursor="pointer"
                                        >
                                            {dot ? <StockDot /> : value}
                                        </Typography>
                                    )}
                                </Stock>
                            </Box>
                        }
                        {isLargeScreen && salesperson && (
                            <form onSubmit={handleFormSubmit}>
                                <Box display="flex" padding="10px 0">
                                    <Typography
                                        customVariant="body"
                                        texttransform="uppercase"
                                        fontWeight={400}
                                    >
                                        MINI:&nbsp;
                                    </Typography>
                                    <MiniInput
                                        inputValue={inputValue}
                                        minq={minq}
                                        handleInputChange={handleInputChange}
                                    />
                                    <MiniButton
                                        inputValue={inputValue}
                                        buttonText="CHANGE MINI"
                                    />
                                </Box>
                            </form>
                        )}
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        padding="0 0 8px 0"
                    >
                        <QuantityButton
                            data={data}
                            cartSize={CartButtonSizes.Large}
                            productUnitsCount={productUnitsCount}
                        />
                    </Box>
                </Box>
                <Separator
                    colorBorder={"#37474F"}
                    direction={SeparatorDirection.Horizontal}
                />

                {showComment && <>
                    <Box padding="10px 0">
                        {t("productsList.tableHeader.comment")}:
                    </Box>
                    {comment}
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" padding="10px 0">
                        <DeleteOutlinedIcon onClick={deleteComment} style={{ cursor: "pointer" }} />
                    </Box>
                </>}
                {showTags &&
                    <>
                        {showComment &&
                            <Separator
                                colorBorder={"#37474F"}
                                direction={SeparatorDirection.Horizontal}
                            />
                        }
                        <Box padding="10px 0">
                            {t("productsList.tableHeader.tags")}:
                        </Box>
                        <SelectedCategoriesWrapper>
                            {tags?.map((tag) => (
                                <Chip
                                    label={tag[1]}
                                    onDelete={() => handleClickDelete(tag)}
                                    key={tag[0]}
                                    style={{ margin: "5px" }}
                                    clickable={false}
                                />
                            ))
                            }
                        </SelectedCategoriesWrapper>
                    </>
                }
            </Box>
            {showMiniMessage && (
                <div>
                    <MiniMessage
                        message={MiniChangeMessage}
                        fontColor={formSuccess ? "green" : "#f28a22"}
                        onClose={async() => {
                            setShowMiniMessage(false);
                            setLoading(true);
                            await dispatch(fetchProductDetails(id, 1));
                            setLoading(false);
                            setIsOverlayVisible(false);
                            setInputValue("");

                        }}
                    />
                    <Overlay show={true} />
                </div>
                
            )}
            {loading && (
                <>
                    {setIsOverlayVisible(true)}
                    <Overlay show={true} />
                </>
            )}
            {loading && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    width="100%"
                    height="100%"
                    background="rgba(255, 255, 255, 0.7)"
                >
                    <StyledCircularProgress />
                </Box>
            )}

        </MainBox >
    );
};
