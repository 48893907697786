import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import React from "react";
import { useTranslation } from "react-i18next";
import CheckCircle from '@material-ui/icons/CheckCircle'
import { ImageWithZoom } from "../ImageWithZoom";
import {
    ItemCardWrapper,
    ItemSpec,
    TitleWrapper,
    CarouselWrapper,
} from "./style";
import { StyledCarousel } from "../ItemDetailsModal/components/ItemDetailsHeader/style";
import { ImageWithZoomCarousel } from "../ImageWithZoomCarousel";

export const ItemCard = ({ data, onTitleClick, images = [] }) => {
    const {
        description: title,
        ak_code: akCode,
        vendor,
        image,
        autokada_code = '',
        name = '',
        stock_icon = '',
    } = data;

    const { t } = useTranslation();

    return (
        <ItemCardWrapper>
        <Box
            width="60px"
            minHeight="48px"
            maxHeight="100px"
            height="50%"
            marginRight="5px"
        >
                {images.length === 0 && (
                    <ImageWithZoom
                        src={
                            image
                                ? `${image}`
                                : "/product-dot.svg"
                        }
                        popupSize={{ width: "100%", height: "100%" }}
                        imgSize = {image && {imgWidth: "50px", imgHeight: "50px", width: "50px"}}
                    />
                )}
                <CarouselWrapper width="60px">
                    {Boolean(images.length) && (
                        <StyledCarousel
                            indicators={false}
                            autoPlay={false}
                            navButtonsAlwaysVisible
                            navButtonsAlwaysInvisible={images.length <= 1}
                        >
                            {images.map(({ url }) => (
                                <ImageWithZoomCarousel
                                    key={url}
                                    src={
                                        url
                                            ? `${url}`
                                            : "/product-dot.svg"
                                    }
                                    placement={"top"}
                                    currentPhotos={images}
                                    marginTop={-194}
                                    width={200}
                                    popupSize={{
                                        width: "200px",
                                        height: "200px",
                                    }}
                                />
                            ))}
                        </StyledCarousel>
                    )}
                </CarouselWrapper>
            </Box>
            <Box flex="1" display="flex" flexDirection="column">
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginBottom="12px"
                >
                    <TitleWrapper onClick={onTitleClick}>{title}</TitleWrapper>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <ItemSpec>{`${t(
                            "productsList.tableHeader.AKCode"
                        )}: ${autokada_code || akCode}`}</ItemSpec>
                    </Grid>
                    <Grid item xs={6}>
                        <ItemSpec>{`${t(
                            "productsList.tableHeader.vendor"
                        )}: ${name || vendor}`}</ItemSpec>
                    </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" flexDirection="row">
                                <ItemSpec>
                                    {t("productsList.tableHeader.stock")}:
                                </ItemSpec>
                                <ItemSpec color={stock_icon}>
                                    <CheckCircle />
                                </ItemSpec>
                            </Box>
                        </Grid>
                </Grid>
            </Box>
        </ItemCardWrapper>
    );
};
