import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { MobileHistoryItem } from "./components/MobileHistoryItem";
import { HistoryList } from "./components/HistoryList";
import { StyledCircularProgress } from "../../../../../CircularProgress";


const PurchaseHistory = ({
    purchaseHistory = [],
    isLoadingPurchaseHistory,
    isPhoneScreen,
}) => {
    return (
        <Box
            minHeight="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {isLoadingPurchaseHistory && (
                <Box justifyContent="center" my="xl">
                    <StyledCircularProgress disableShrink/>
                </Box>
            )}
            {!isLoadingPurchaseHistory &&
                (isPhoneScreen ? (
                    <Grid container spacing={2} direction="column">
                        {Boolean(purchaseHistory.length) &&
                            purchaseHistory.map((purchase) => (
                                <Grid
                                    item
                                    key={`history-item-${purchase.id}`}
                                >
                                    <MobileHistoryItem data={purchase} />
                                </Grid>
                            ))}
                    </Grid>
                ) : (
                    Boolean(purchaseHistory.length) && (
                        <HistoryList purchases={purchaseHistory} />
                    )
                ))}
        </Box>
    );
};

export default memo(PurchaseHistory);
