import React from "react";

import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import { PriceOfferSide } from "./components/PriceOffersHistoryList";

export const PriceOffersHistory = () => {
    return (
        <div>
            <Header />
            <Wrapper>
                <PriceOfferSide />
            </Wrapper>
        </div>
    );
};
