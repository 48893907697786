import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircle from "@material-ui/icons/CheckCircle";
import LocalShipping from "@material-ui/icons/LocalShipping";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from "react-i18next";
import { changeDiscount, changeVAT, setSearchResetPage } from "../../core/redux/actions/global";
import {
    fetchProductDetails,
    fetchProducts,
    fetchTopProducts,
    updatePrices,
    fetchPublicProducts,
    fetchNewProducts,
    fetchTagItems,
} from "../../core/redux/asyncActions/global";
import { Box } from "../../components/Box";
import Item from "./item";
import { ItemDetailsModal } from "./components/ItemDetailsModal";

import {
    FilterLine,
    Filters,
    FilterTitle,
    Wrapper,
    SelectedCategoriesWrapper,
    SelectedCategoryText,
    ProductListHeader,
    PublicProductListHeaderItem,
    Title,
    Availability,
} from "./style";
import * as globalActions from "../../core/redux/actions/global";
import TourPopup from "../Tour/TourPopup";
import Grid from '@material-ui/core/Grid';
import StyledPagination from "../Pagination";
import { OrangeSwitch } from "../Switch";
import { StyledCircularProgress } from "../CircularProgress";
import { useTheme as useThemeMaterial } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ProductsList } from "./components/ProductsList";
import PublicItem from "./public-item";
import { selectFormSubmissionStatus } from "../../core/redux/selectors/global"
import { updateFormSubmissionStatus } from "../../core/redux/actions/global";

export function ProductListing({ showPublicProducts = false }) {
    const discountFilterRef = useRef();
    const vatFilterRef = useRef();
    const parametersRef = useRef();
    const productListRef = useRef();
    const formSubmissionStatus = useSelector(selectFormSubmissionStatus);

    const { t } = useTranslation();
    const { with_discount = "", with_vat = "" } = useSelector(
        (state) => state.global.userData || {}
    );
    const {
        selectedItemCategory,
        selectedItemGroup,
        selectedItemModel,
        onlyInStock,
        selectedVendors,
        lang,
        searchQuery,
        country,
        products,
        productsLoading,
        menuProducts,
        searchResetPage,
        tagItemList,
        userData,
        pageCount,
        newProductsVisible,
        selectedTag
    } = useSelector((state) => state.global);
    const { publicProducts } = useSelector((state) => state.global);
    const themeMaterial = useThemeMaterial();
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));
    const currentProduct = useSelector(
        (state) => state.global?.currentProduct || null
    );
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showTopProducts, setShowTopProducts] = useState(false);

    const handleVatChange = async ({ target: { checked } }) => {
        dispatch(changeVAT(checked));
        dispatch(
            updatePrices(
                products.map((prod) => prod.id).join(","),
                with_discount,
                checked
            )
        );
    };

    const handleDiscountChange = async ({ target: { checked } }) => {
        dispatch(changeDiscount(checked));
        dispatch(
            updatePrices(
                products.map((prod) => prod.id).join(","),
                checked,
                with_vat
            )
        );
    };

    useEffect(() => {
        if (searchResetPage !== undefined && searchResetPage === true) {
            dispatch(setSearchResetPage(false));
            setPage(1);
            setTotalPages(1);
        }
    }, [searchResetPage, dispatch]);

    useEffect(() => {
        let fetchPage = page;
        if (showPublicProducts && !userData) {
            dispatch(fetchPublicProducts(
                page,
                selectedItemCategory?.cid,
                selectedItemGroup?.gid,
                selectedItemModel?.mid,
                onlyInStock,
                selectedVendors?.map((vendor) => vendor.id).join(","),
                searchQuery
            ));
        } else {
            if (newProductsVisible) {
                dispatch(fetchNewProducts(fetchPage, userData?.scope));
                setShowTopProducts(false);
            } else {
                if (selectedItemCategory ||
                    selectedItemGroup ||
                    selectedItemModel ||
                    onlyInStock ||
                    (selectedVendors.length !== 0) ||
                    searchQuery
                ) {

                    if (searchResetPage !== undefined && searchResetPage === true) {
                        fetchPage = 1;
                    }
                    dispatch(fetchProducts(
                        fetchPage,
                        selectedItemCategory?.cid,
                        selectedItemGroup?.gid,
                        selectedItemModel?.mid,
                        onlyInStock,
                        selectedVendors?.map((vendor) => vendor.id).join(","),
                        searchQuery
                    ));
                    setShowTopProducts(false);
                }
                else {
                    if (selectedTag) {
                        dispatch(fetchTagItems(selectedTag));
                    } else {
                        setShowTopProducts(true);
                        dispatch(fetchTopProducts());
                    }
                }
            }
        }
    },
        [page,
            lang,
            selectedItemCategory,
            selectedItemGroup,
            selectedItemModel,
            onlyInStock,
            selectedVendors.length,
            searchQuery,
            country,
            newProductsVisible,
            dispatch,
            showPublicProducts,
        ]);

    const handleCloseItemDetailsPopup = useCallback((currentPage) => {
        let fetchPage = currentPage
        if (showPublicProducts && !userData) {
            dispatch(fetchPublicProducts(
                currentPage,
                selectedItemCategory?.cid,
                selectedItemGroup?.gid,
                selectedItemModel?.mid,
                onlyInStock,
                selectedVendors?.map((vendor) => vendor.id).join(","),
                searchQuery
            ));
        } else {
            if (newProductsVisible) {
                dispatch(fetchNewProducts(currentPage, userData?.scope));
                setShowTopProducts(false);
            } else {
                if (selectedItemCategory ||
                    selectedItemGroup ||
                    selectedItemModel ||
                    onlyInStock ||
                    (selectedVendors.length !== 0) ||
                    searchQuery
                ) {

                    if (searchResetPage !== undefined && searchResetPage === true) {
                        fetchPage = 1;
                    }
                    dispatch(fetchProducts(
                        fetchPage,
                        selectedItemCategory?.cid,
                        selectedItemGroup?.gid,
                        selectedItemModel?.mid,
                        onlyInStock,
                        selectedVendors?.map((vendor) => vendor.id).join(","),
                        searchQuery
                    ));
                    setShowTopProducts(false);
                }
                else {
                    if (selectedTag) {
                        dispatch(fetchTagItems(selectedTag));
                    } else {
                        setShowTopProducts(true);
                        dispatch(fetchTopProducts());
                    }
                }
            }
        }
        dispatch(updateFormSubmissionStatus(false));
        dispatch(globalActions.clearProductDetails());
    }, [dispatch, newProductsVisible, selectedTag, showPublicProducts, userData]);
    

    const handleCloseSelectedVendor = (vendor_id) => {
        for (let i = 0; i < selectedVendors.length; i++) {
            if (vendor_id === selectedVendors[i].id) {
                selectedVendors.splice(i, 1);
            }
        }
        dispatch(globalActions.setSelectedVendors(selectedVendors));
    };


    useEffect(() => {
        setTotalPages(pageCount);
    }, [products, publicProducts, showPublicProducts, pageCount]);

    const publicAvailibility = ["green", "yellow", "orange", "grey", "transit"];

    return (
        <Wrapper>
            {showPublicProducts &&
                <>
                    <Title>{t("productsList.productCatalog")}</Title>
                    {publicAvailibility.map((color) => (
                        <Availability>
                            {color === "transit" ?
                                <LocalShipping />
                                :
                                <CheckCircle style={{ color: color }} />}
                            {" "}
                            {t(`productsList.availability.${color}`)}
                        </Availability>
                    ))}
                </>
            }
            <Grid container style={{ margin: '20px 0' }}>
                <Grid item xs={6}>
                    {menuProducts !== "loading" &&
                        <>
                            <SelectedCategoriesWrapper>
                                {selectedItemCategory &&
                                    <SelectedCategoryText>
                                        {selectedItemCategory.title}
                                        <CloseIcon onClick={() => dispatch(globalActions.setSelectedItemCategory(null))} />
                                    </SelectedCategoryText>}
                                {selectedItemGroup &&
                                    <SelectedCategoryText>
                                        {selectedItemGroup.title}
                                        <CloseIcon onClick={() => dispatch(globalActions.setSelectedItemGroup(null))} />
                                    </SelectedCategoryText>}
                                {selectedItemModel &&
                                    <SelectedCategoryText>
                                        {selectedItemModel.title}
                                        <CloseIcon onClick={() => dispatch(globalActions.setSelectedItemModel(null))} />
                                    </SelectedCategoryText>}
                            </SelectedCategoriesWrapper>
                            {selectedVendors &&
                                <SelectedCategoriesWrapper ref={parametersRef} type="vendor">
                                    {selectedVendors.map((vendor) => (
                                        <SelectedCategoryText key={vendor.id}>
                                            {vendor.text}
                                            <CloseIcon
                                                onClick={() => handleCloseSelectedVendor(vendor.id)}
                                            />
                                        </SelectedCategoryText>
                                    ))}
                                </SelectedCategoriesWrapper>}
                        </>}
                    <TourPopup
                        step={11}
                        placement="bottom-start"
                        anchorEl={parametersRef.current}
                    >
                        {t('appTour.searchParameters')}
                    </TourPopup>
                </Grid>
                {!showPublicProducts &&
                    <Grid item xs={6}>
                        <Filters>
                            <FilterLine ref={discountFilterRef}>
                                <FilterTitle>{t("productsList.withDiscount")}</FilterTitle>
                                <FormControlLabel
                                    label=""
                                    control={
                                        <OrangeSwitch
                                            checked={with_discount}
                                            onChange={handleDiscountChange}
                                            name="checkedA"
                                        />
                                    }
                                />
                            </FilterLine>
                            <TourPopup
                                step={3}
                                placement={isMobileScreen ? 'bottom-end' : 'left-start'}
                                anchorEl={discountFilterRef?.current}
                            >
                                {t('appTour.grossPrices')}
                            </TourPopup>
                            <FilterLine ref={vatFilterRef}>
                                <FilterTitle>{t("productsList.withVAT")}</FilterTitle>
                                <FormControlLabel
                                    control={
                                        <OrangeSwitch
                                            checked={with_vat}
                                            onChange={handleVatChange}
                                            name="checkedA"
                                        />
                                    }
                                    label=""
                                />
                            </FilterLine>
                            <TourPopup
                                step={4}
                                placement={isMobileScreen ? 'top-end' : 'left-start'}
                                anchorEl={vatFilterRef?.current}
                            >
                                {t('appTour.vatPrices')}
                            </TourPopup>
                        </Filters>
                    </Grid>}
            </Grid>
            {showPublicProducts &&
                <ProductListHeader>
                    <PublicProductListHeaderItem />
                    <PublicProductListHeaderItem>
                        {t("productsList.tableHeader.AKCode").toUpperCase()}
                    </PublicProductListHeaderItem>
                    <PublicProductListHeaderItem>
                        {t("productsList.tableHeader.vendor").toUpperCase()}
                    </PublicProductListHeaderItem>
                    <PublicProductListHeaderItem>
                        {t("productsList.tableHeader.product").toUpperCase()}
                    </PublicProductListHeaderItem>
                    <PublicProductListHeaderItem>
                        {t("productsList.tableHeader.stock").toUpperCase()}
                    </PublicProductListHeaderItem>
                </ProductListHeader>}
            <div ref={productListRef}>
                {(currentProduct && !showPublicProducts) && (
                    <ItemDetailsModal
                        onCloseDetails={
                            formSubmissionStatus ? () =>
                            handleCloseItemDetailsPopup(page) : undefined
                        }
                    />
                )}
                {(productsLoading) ? (
                    <Box justifyContent="center" my="xl">
                        <StyledCircularProgress disableShrink />
                    </Box>
                ) : (
                    (!showPublicProducts) ?
                        (isMobileScreen ?
                            (products.map((product, index) => (
                                <Item
                                    data={product}
                                    key={product.id}
                                    handleItemPopup={(id) => dispatch(fetchProductDetails(id, 1))}
                                    type="listing"
                                    tag_id={tagItemList?.tag_id}
                                    itemNo={index}
                                />
                            ))) :
                            <>
                                <ProductsList products={products}
                                    general={true} type="listing" />
                            </>
                        )
                        :
                        publicProducts.map((product) => (
                            <PublicItem
                                data={product}
                                key={product.id}
                                type="listing"
                            />
                        ))
                )
                }
            </div>
            <TourPopup
                step={12}
                placement="bottom"
                anchorEl={productListRef.current}
                scroll={false}
            >
                {t('appTour.particularProduct')}
            </TourPopup>
            {(!productsLoading && pageCount > 1 && !showTopProducts) &&
                <Box justifyContent="center">
                    <StyledPagination
                        pageNumber={page}
                        count={totalPages}
                        setPageNumber={(pageNumber) => setPage(pageNumber)}
                    />
                </Box>
            }
        </Wrapper >
    );
}