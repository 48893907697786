import React, { useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    CartImage,
    CartImageWrapper,
    CartInfoListItem,
    CartInfoTitle,
    StyledCard,
} from "../Carts/style";
import { useTranslation } from "react-i18next";
import { fetchFavourites, fetchProductDetails, removeFavourite } from "../../../core/redux/asyncActions/global";
import { CardHeader } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import QuantityButton from "../../Listing/components/QuantityButton";
import { StyledFavourtiteChecked } from "../Carts/style";
import { REMOVE_FAVOURITE_SUCCESS } from "../../../core/redux/actionTypes/global";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Popper } from "../../Popper";
import { FavoriteTipsFlyout } from "../../Listing/components/QuantityButton/FavoriteTipsFlyout";

export default function FavoriteItem({ data }) {
    const [popperVisibility, setPopperVisibility] = useState(false);
    const buttonRef = useRef(null);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const togglePopper = useCallback(() => {
        setPopperVisibility((curValue) => !curValue);
    }, [setPopperVisibility]);
    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, [setPopperVisibility]);
    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, [setPopperVisibility]);

    const textProps = isMobileScreen
        ? {
            onClick: togglePopper,
        }
        : {
            onMouseEnter: openPopper,
            onMouseLeave: closePopper,
        };
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleItemPopup = async (e, id) => {
        e.stopPropagation();
        dispatch(fetchProductDetails(id, 1));
    };

    const handleRemoveFavourite = async () => {
        const { type } = await dispatch(removeFavourite(data.item_id));
        if (type === REMOVE_FAVOURITE_SUCCESS) {
            dispatch(fetchFavourites());
        }
    }
    // eslint-disable-next-line
    const [productUnits, setProductUnits] = useState(1);

    const productUnitsCount = (unitsCount) => {
        setProductUnits(unitsCount);
    }

    return (
        <>
            <StyledCard>
                <CardHeader
                    avatar={
                        <CartImageWrapper>
                            <CartImage
                                src={data?.image
                                    ? `${data?.image}`
                                    : "/product-dot.svg"
                                }
                                alt={data?.name}
                            />
                        </CartImageWrapper>}
                    action={
                        <div ref={buttonRef} {...textProps}>
                            <StyledFavourtiteChecked onClick={handleRemoveFavourite} />
                        </div>
                    }
                    title={<CartInfoTitle style={{ cursor: "pointer" }} onClick={(e) => handleItemPopup(e, data?.item_id)}>{data?.description || data?.name}</CartInfoTitle>}

                    subheader={
                        <>
                            <Grid container spacing={1} style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                <Grid item container spacing={1} xs={12} md={6}>
                                    <Grid item xs={6} md={12}>
                                        <CartInfoListItem>
                                            {t("productsList.tableHeader.AKCode")}: {data?.ak_code}
                                        </CartInfoListItem>
                                    </Grid>
                                    <Grid item xs={6} md={12}>
                                        <CartInfoListItem>
                                            {t("productsList.tableHeader.price")}: {data?.priceText || parseFloat(data?.unit_price).toFixed(2)}
                                        </CartInfoListItem>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <QuantityButton data={data} fav={true} productUnitsCount={productUnitsCount} onClick={(e) => e?.stopPropagation} />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            </StyledCard>
            {!isMobileScreen &&
                <Popper
                    id={data?.item_id}
                    visibility={popperVisibility}
                    anchorEl={buttonRef?.current}
                    onClickAway={closePopper}
                    ignoreBackground={true}
                >
                    <FavoriteTipsFlyout isFavorite={true} />
                </Popper>
            }
        </>
    );
}
