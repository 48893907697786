import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';

import {
    CartButton,
    CartImage,
    CartImageWrapper,
    CartInfoBox,
    CartInfoHeader,
    CartInfoList,
    CartInfoListItem,
    CartInfoListWrapper,
    CartInfoPrice,
    CartInfoTitle,
    CartItem,
    ActionButtons,
    FlyoutWrapper
} from "./style";
import { addItemToBasket } from "../../../core/redux/asyncActions/carts";
import { useTranslation } from "react-i18next";
import { fetchProductDetails, fetchTags } from "../../../core/redux/asyncActions/global";
import { Popper } from "../../Popper";
import { CommentFlyout } from "./CommentFlyout";
import { TagsFlyout } from "./TagsFlyout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme as useThemeMaterial } from "@material-ui/core/styles";
import Typography from "../../Typography";
import TourPopup from "../../Tour/TourPopup";


export default function CartsItem({ data, isFirst=false }) {
    const sourceLocation = useSelector((state) => state.carts.sourceLocation);
    const [popperComVisibility, setPopperComVisibility] = useState(false);
    const [popperTagVisibility, setPopperTagVisibility] = useState(false);
    const [popperTipComVisibility, setPopperTipComVisibility] = useState(false);
    const [popperTipTagVisibility, setPopperTipTagVisibility] = useState(false);
    const themeMaterial = useThemeMaterial();
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));
    const commentRef = useRef();
    const tagsRef = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const handleRemoveItem = (e) => {
        e?.stopPropagation()
        const { cart_id = '', item_id = '', count, title } = data
        dispatch(addItemToBasket(cart_id, item_id, -count, title, data.description, false, sourceLocation))
    };

    const openCommentPopper = useCallback((e) => {
        setPopperTipComVisibility(true);
    }, [setPopperTipComVisibility]);

    const closeCommentPopper = useCallback((e) => {
        setPopperTipComVisibility(false);
    }, [setPopperTipComVisibility]);

    const textCommentProps = {
        onMouseEnter: openCommentPopper,
        onMouseLeave: closeCommentPopper,
    };

    const openTagPopper = useCallback((e) => {
        setPopperTipTagVisibility(true);
    }, [setPopperTipTagVisibility]);

    const closeTagPopper = useCallback((e) => {
        setPopperTipTagVisibility(false);
    }, [setPopperTipTagVisibility]);

    const textTagProps = {
        onMouseEnter: openTagPopper,
        onMouseLeave: closeTagPopper,
    };

    const handleItemPopup = async (e, id) => {
        e?.stopPropagation();
        dispatch(fetchProductDetails(id, 1))
    };

    const closePoppers = useCallback((e) => {
        e?.stopPropagation();
        setPopperComVisibility(false);
        setPopperTagVisibility(false);
    }, [setPopperComVisibility, setPopperTagVisibility]);

    const toggleCommentPopper = useCallback(
        (e) => {
            e?.stopPropagation();
            setPopperComVisibility((curValue) => !curValue);
        },
        []
    );

    const toggleTagsPopper = useCallback(
        (e) => {
            e?.stopPropagation();
            dispatch(fetchTags());
            setPopperTagVisibility((curValue) => !curValue);
        },
        [dispatch]
    );

    return (
        <>
            <CartItem isFirst={isFirst} style={{ cursor: "pointer" }} onClick={(e) => !isMobileScreen && handleItemPopup(e, data.item_id, data.vendor_item_code)}>
                <CartImageWrapper>
                    <CartImage
                        src={data?.image
                            ? `${data?.image}`
                            : "/product-dot.svg"
                        }
                        alt="product"
                    />
                </CartImageWrapper>
                <CartInfoBox>
                    <CartInfoHeader>
                        <CartInfoTitle onClick={(e) => isMobileScreen && handleItemPopup(e, data.item_id, data.vendor_item_code)}>{data.description || data.name}</CartInfoTitle>
                        <CartInfoPrice>{data.priceText || parseFloat(data.total).toFixed(2)}</CartInfoPrice>
                    </CartInfoHeader>
                    <CartInfoListWrapper>
                        <CartInfoList>
                            <CartInfoListItem>
                                {t("productsList.tableHeader.AKCode")}: {data?.akCode || data?.autokada_code}
                            </CartInfoListItem>
                            <CartInfoListItem>{t("productsList.tableHeader.oem")}: {data?.vendor_item_code || data?.item_id}</CartInfoListItem>
                            <CartInfoListItem>
                                {t("productsList.cartInfoSection.quantity")} {data?.quantity || data?.count}
                            </CartInfoListItem>
                            <CartInfoListItem>
                                {t("productsList.tableHeader.price")}: {data?.priceText || parseFloat(data?.unit_price).toFixed(2)}
                            </CartInfoListItem>
                        </CartInfoList>
                        <ActionButtons>
                            <CartButton onClick={toggleTagsPopper}>
                                <DirectionsCarOutlinedIcon {...textTagProps} ref={tagsRef} />
                            </CartButton>
                            <CartButton onClick={toggleCommentPopper}>
                                <InsertCommentOutlinedIcon {...textCommentProps} ref={commentRef} />
                            </CartButton>
                            <CartButton onClick={handleRemoveItem}>
                                <DeleteOutlinedIcon />
                            </CartButton>
                        </ActionButtons>
                    </CartInfoListWrapper>
                </CartInfoBox>
            </CartItem>
            {(isFirst && tagsRef.current) &&
                <TourPopup
                    step={20}
                    anchorEl={tagsRef.current}
                    placement={isMobileScreen? "bottom-end" : "left-tag"}
                >
                    {t('appTour.tagItem')}
                </TourPopup>
            }
            {(isFirst && commentRef.current) &&
                <TourPopup
                    step={21}
                    anchorEl={commentRef.current}
                    placement={isMobileScreen? "bottom-end" : "left-comment"}
                >
                    {t('appTour.commentItem')}
                </TourPopup>
            }
            <Popper
                id={data?.item_id}
                visibility={popperComVisibility}
                anchorEl={commentRef?.current}
                onClickAway={closePoppers}
                placement={"left-start"}
                ignoreBackground={!popperComVisibility}
            >
                <CommentFlyout id={data?.item_id} title={data?.description} close={toggleCommentPopper} />
            </Popper>
            <Popper
                id={data?.item_id}
                visibility={popperTipComVisibility}
                anchorEl={commentRef?.current}
                onClickAway={closeCommentPopper}
                ignoreBackground={true}
            >
                <FlyoutWrapper style={{ marginRight: "5px" }} >
                    <Typography>{t('productsList.commentFlyout.add')}</Typography></FlyoutWrapper>
            </Popper>
            <Popper
                id={data?.item_id}
                visibility={popperTagVisibility}
                anchorEl={tagsRef?.current}
                onClickAway={closePoppers}
                placement={"left-start"}
                ignoreBackground={!popperTagVisibility}
            >
                <TagsFlyout id={data?.item_id} title={data?.description} close={toggleTagsPopper} />
            </Popper>
            <Popper
                id={data?.item_id}
                visibility={popperTipTagVisibility}
                anchorEl={tagsRef?.current}
                onClickAway={closeTagPopper}
                ignoreBackground={true}
            >
                <FlyoutWrapper style={{ marginRight: "5px" }} >
                    <Typography>{t('productsList.tagFlyout.add')}</Typography></FlyoutWrapper>
            </Popper>
        </>
    );
}
