import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChooseCartTitle, CartFlyoutWrapper } from "../../Listing/components/CartButton/style";
import Button from "../../Button";
import { Input } from "../../Form";
import Typography from "../../Typography";
import { Box } from "../../Box";
import { UserMenuSeparator } from "../style";
import { SelectedCategoriesWrapper } from "../../Listing/style";
import { Chip } from "@material-ui/core";
import { fetchItemTagList, removeTagItem, setTagItem } from "../../../core/redux/asyncActions/global";


export const TagsFlyout = React.forwardRef(
    ({ close, id, title }, ref) => {
        const [newTag, setNewTag] = useState("");
        const [errorMessageVisible, setErrorMessageVisible] = useState(false);
        const [isHovering, setIsHovering] = useState(false);
        const [hoveredTagId, setHoveredTagId] = useState(null);
        const { t } = useTranslation();
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(fetchItemTagList(id));
        }, [dispatch, id]);

        const handleMouseEnter = (tagId) => {
            setIsHovering(true);
            setHoveredTagId(tagId);
          };
        
          const handleMouseLeave = () => {
            setIsHovering(false);
            setHoveredTagId(null);
          };

        const { tagsList } = useSelector(
            (state) => state.global
        );

        const { tagItemList } = useSelector(
            (state) => state.global
        );

        const handleTagItem = (e) => {
            const tag = e?.target?.textContent;
            dispatch(setTagItem(tag, id, title));
            close();
        };

        const handleUntagItem = (tag) => {
            dispatch(removeTagItem(tag.id, tag.title, id, title));
            close();
        }

        const createTag = (e) => {
            if (newTag) {
                dispatch(setTagItem(newTag, id, title));
                close();
            } else {
                setErrorMessageVisible(true);
            }
        };

        const handleTag = useCallback(
            ({ target: { value } }) => {
                setNewTag(value);
                if (newTag) {
                    setErrorMessageVisible(false);
                }
            },
            [newTag]
        );

        const stopPropagation = (e) => {
            e.stopPropagation()
        }

        return (
            <CartFlyoutWrapper ref={ref} onClick={stopPropagation}>
                <div>
                    <ChooseCartTitle>
                        {t('header.tags.addTag')}
                    </ChooseCartTitle>
                </div>
                <SelectedCategoriesWrapper type="vendor" style={{ marginBottom: "10px" }}>
                    {tagsList?.results?.map((tag) => {
                        const isTagSelected = tagItemList?.results?.results?.some(
                            item => item.tag_id === tag.id && item.item_id === id
                        );
                        const bgColor = isTagSelected ?
                            (isHovering && hoveredTagId === tag.id ? "#E0E0E0" : "rgb(127 238 127)")
                            : (isHovering && hoveredTagId === tag.id ? "rgb(127 238 127)" : "#E0E0E0");
                        
                        return (
                            <Chip
                                label={tag.title}
                                onClick={(e) => 
                                    isTagSelected ? handleUntagItem(tag) : handleTagItem(e)  
                                }
                                key={tag.id}
                                style={{
                                    margin: "5px",
                                    backgroundColor: bgColor,
                                    transition: "background-color 0.3s ease",
                                }}
                                onMouseEnter={() => handleMouseEnter(tag.id)}
                                onMouseLeave={handleMouseLeave}
                            />
                        )
                    })}
                </SelectedCategoriesWrapper>
                <UserMenuSeparator />
                <Box mb="xs">
                    <Input
                        type="text"
                        value={newTag}
                        placeholder={
                            t('header.tags.inputTag')
                        }
                        error={errorMessageVisible}
                        onClick={(e) => e?.stopPropagation}
                        onChange={handleTag}
                    />
                </Box>
                {errorMessageVisible &&
                    <Box mb="xs" justifyContent="center">
                        <Typography color="error" customVariant="caption">
                            {t('header.tags.addTag')}!
                        </Typography>
                    </Box>
                }
                <Button
                    buttonVariant={"Light"}
                    height="48px"
                    onClick={createTag}
                >
                    {t('header.tags.createTag').toUpperCase()}
                </Button>
            </CartFlyoutWrapper>
        );
    }
);
