import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import {
    ItemVehicleWrapper,
    TitleTypography,
    TxtTypography
} from "../../../LinkedVehicles/components/MobileVehicleList/style";

const MobileHistoryItem = ({ data }) => {
    const {
        order_date: date,
        count,
        unit_price: price,
        tag_titles: tags,
        user
    } = { ...data };
    const { t } = useTranslation();

    return (
        <ItemVehicleWrapper
            item
            xs={12}
            container
            direction="row"
            justify="flex-start"
            alignItem="flex-start"
        >
            <Grid item xs={12}>
                <TitleTypography>
                    {date}
                </TitleTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t("productsList.tablePurchaseHistoryHeader.count")}: ${count}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t("productsList.tablePurchaseHistoryHeader.unitPrice")}: ${price}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t("productsList.tablePurchaseHistoryHeader.tags")}: ${tags}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t("productsList.tablePurchaseHistoryHeader.user")}: ${user}`}
                </TxtTypography>
            </Grid>
        </ItemVehicleWrapper>
    );
};

export default memo(MobileHistoryItem);
