import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { debounce, useMediaQuery, useTheme as useThemeMaterial } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Skeleton } from "@material-ui/lab";
import { MultipleAutocomplete } from "../MultipleAutocomplete";
import {
    Categories,
    CategoryList,
    CategoryListItem,
    CategoryListItemLink,
    CategorySubList,
    CategorySubListItem,
    CategorySubListItemLink,
    DrawerTopSection,
    Logo,
    LogoWrapper,
    Overlay,
    SelectFormControl,
    StockFilterText,
    StockFilterWrapper,
    SubCategorySubListItemLink,
    Title,
    Wrapper,
} from "./style";
import {
    hideMenu,
    setOnlyInStock,
    setSelectedItemCategory,
    setSelectedItemGroup,
    setSelectedItemModel,
    setSelectedVendors,
    showNewProducts,
} from "../../core/redux/actions/global";
import { HOME_PATH } from "../../core/routes";
import { fetchVendors } from "../../core/redux/asyncActions/global";
import TourPopup from "../Tour/TourPopup";
import { OrangeSwitch } from "../Switch";

export default function Drawer() {
    const catalogTitleRef = useRef();
    const stockFilterRef = useRef();
    const manufacturersMenuRef = useRef();

    const {
        selectedItemCategory: itemCategory,
        selectedItemGroup: itemGroup,
        selectedItemModel: itemModel,
        onlyInStock,
        vendors,
        isDrawerOpen,
        menuProducts: productsList,
        selectedVendors,
        lang,
        searchQuery,
        newProductsVisible,
    } = useSelector((state) => state.global);

    const categories = useSelector(
        (state) => state.global?.oemVehicles?.categories || []
    );

    const { isLoading } = useSelector(
        (state) => state.global?.oemVehicles || {}
    );

    const themeMaterial = useThemeMaterial();
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));
    const history = useHistory();
    const { vin = "", unitId = "", plate = "" } = useParams();
    const [vendorsQuery, setVendorsQuery] = useState(null);
    const [refHeight, setRefHeight] = useState(0);
    const [isDrawerMenuVisible, setIsDrawerMenuVisible] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isIncludesOemPath = window.location.pathname.includes("/oem") || window.location.pathname.includes("/plate");
    const isIncludesPlatePath = window.location.pathname.includes("/plate");

    const menuProducts = useMemo(
        () => (isIncludesOemPath ? categories : productsList),
        [isIncludesOemPath, categories, productsList]
    );

    useEffect(() => {
        if (!isIncludesOemPath) {
            dispatch(
                fetchVendors(
                    vendorsQuery,
                    onlyInStock,
                    itemCategory?.cid,
                    itemGroup?.gid,
                    itemModel?.mid,
                    searchQuery
                )
            );

        }
    }, [
        lang,
        isIncludesOemPath,
        itemCategory,
        itemGroup,
        itemModel,
        onlyInStock,
        vendorsQuery,
        dispatch,
        searchQuery
    ]);

    const swipeHandler = useSwipeable({
        onSwipedLeft: () => dispatch(hideMenu()),
    });

    const handleNewProducts = () => {
        dispatch(showNewProducts(!newProductsVisible));
    };

    const handleSwitchChange = () => {
        dispatch(setOnlyInStock(!onlyInStock));
    };

    const handleDocumentClick = (e) => {
        e.stopPropagation();
        dispatch(hideMenu());
    };

    const handleSubCategorySelect = (category) => {
        if (category?.cid === itemCategory?.cid) {
            dispatch(setSelectedItemCategory(null));
        } else {
            dispatch(setSelectedItemCategory(category));
        }
        if (isIncludesOemPath && unitId) {
            history.push(`/oem/${vin}`);
        }
        if (isIncludesPlatePath && unitId) {
            history.push(`/plate/${plate}`);
        }
    };

    const handleSecondCategorySelect = (event, category) => {
        event.stopPropagation();
        if (itemGroup?.gid === category?.gid) {
            dispatch(setSelectedItemGroup(null));
        } else {
            dispatch(setSelectedItemGroup(category));
        }
        if (isIncludesOemPath && unitId) {
            history.push(`/oem/${vin}`);
        }
        if (isIncludesPlatePath && unitId) {
            history.push(`/plate/${plate}`);
        }
    };

    const handleThirdCategorySelect = (event, category) => {
        event.stopPropagation();
        if (itemModel?.mid === category?.mid) {
            dispatch(setSelectedItemModel(null));
        } else {
            dispatch(setSelectedItemModel(category));
        }
        if (isIncludesOemPath && unitId) {
            history.push(`/oem/${vin}`);
        }
        if (isIncludesPlatePath && unitId) {
            history.push(`/plate/${plate}`);
        }
    };

    useEffect(() => {
        if (menuProducts && menuProducts !== "loading" && !isIncludesOemPath) {
            const category = menuProducts.find(category => category?.cid === itemCategory?.cid);
            if (category?.children) {
                dispatch(setSelectedItemCategory(category));
                const group = category?.children.find(group => group?.gid === itemGroup?.gid);
                if (group?.children) {
                    dispatch(setSelectedItemGroup(group));
                    const model = group?.children.find(model => model?.mid === itemModel?.mid);
                    if (model) {
                        dispatch(setSelectedItemModel(model));
                    }
                }
            }
        }
    }, [menuProducts, isIncludesOemPath, itemCategory?.cid, itemGroup?.gid, itemModel?.mid, dispatch]);

    const handleHomeDirection = () => {
        dispatch(hideMenu());
        setTimeout(() => {
            window.location.href = HOME_PATH;
        }, 500);
    };

    useEffect(() => {
        if (window.innerWidth >= 960) {
            //setRefHeight(document.body.offsetHeight - 228);
            setIsDrawerMenuVisible(true);
        } else {
            setRefHeight(window.innerHeight - 188);

            if (!isDrawerOpen) {
                setIsDrawerMenuVisible(false);
            } else {
                setIsDrawerMenuVisible(true);
            }
        }
    }, [isDrawerOpen]);

    const handleManufacturerChange = (_evt, selectedOptions) => {
        dispatch(setSelectedVendors(selectedOptions));
    };

    const handleManufacturerQueryChange = debounce((evt, query) => {
        setVendorsQuery(query);
    }, 400);


    const Loop = () => {
        var indents = [];
        for (var i = 0; i < 10; i++) {
            indents.push(
                <CategoryListItemLink key={i} style={{ padding: " 0 8px" }}>
                    <Skeleton style={{ width: "100%", height: "45px" }} />
                </CategoryListItemLink>)
        }
        return (
            <>
                {indents}
            </>
        )
    }

    return (
        <>
            <Overlay
                isVisible={isDrawerMenuVisible}
                onClick={handleDocumentClick}
            />
            <Wrapper isVisible={isDrawerMenuVisible} {...swipeHandler}>
                <DrawerTopSection>
                    <LogoWrapper onClick={handleHomeDirection}>
                        <Logo src="/logo_white.svg" />
                    </LogoWrapper>
                    <Title ref={catalogTitleRef}>{t("sideMenu.browseProductCatalog")}</Title>
                    {window.location.pathname.includes("/home") && <CategoryListItemLink onClick={handleNewProducts} isactive={newProductsVisible}> {t("sideMenu.newProducts").toUpperCase()}</CategoryListItemLink>}
                    {(isDrawerOpen || !isMobileScreen) && (
                        <TourPopup
                            step={5}
                            delay={isDrawerOpen ? 500 : 0}
                            placement={isDrawerOpen ? 'bottom' : 'right-start'}
                            anchorEl={catalogTitleRef.current}
                        >
                            {t('appTour.productCatalog')}
                        </TourPopup>
                    )}
                    {!isIncludesOemPath && (
                        <>
                            <StockFilterWrapper ref={stockFilterRef}>
                                <StockFilterText>
                                    {t("sideMenu.onlyItemsOnStock")}
                                </StockFilterText>
                                <FormControlLabel
                                    control={
                                        <OrangeSwitch
                                            checked={onlyInStock}
                                            onChange={handleSwitchChange}
                                            name="checkedA"
                                        />
                                    }
                                />
                            </StockFilterWrapper>
                            {(isDrawerOpen || !isMobileScreen) && (
                                <TourPopup
                                    delay={isDrawerOpen ? 500 : 0}
                                    step={9}
                                    placement={isDrawerOpen ? 'bottom' : 'right-start'}
                                    anchorEl={stockFilterRef.current}
                                >
                                    {t('appTour.itemsInStock')}
                                </TourPopup>
                            )}
                        </>
                    )}
                    {!isIncludesOemPath && (
                        <>
                            <SelectFormControl ref={manufacturersMenuRef} variant="outlined" size="small">
                                <MultipleAutocomplete
                                    options={vendors}
                                    selectedOptions={selectedVendors}
                                    onChange={handleManufacturerChange}
                                    onInputChange={handleManufacturerQueryChange}
                                    placeholder={t("sideMenu.chooseAManufacturer")}
                                />
                            </SelectFormControl>
                            {(isDrawerOpen || !isMobileScreen) && (
                                <TourPopup
                                    step={10}
                                    placement="bottom"
                                    anchorEl={manufacturersMenuRef.current}
                                >
                                    {t('appTour.selectedManufacturer')}
                                </TourPopup>
                            )}
                            {/* <TourPopup
                                step={ 10? }
                                placement={ isDrawerOpen ? 'bottom' : 'right-start' }
                                anchorEl={ manufacturersMenuRef.current }
                            >
                                { t('appTour.manufacturersMenu') }
                            </TourPopup> */}
                        </>
                    )}
                </DrawerTopSection>
                <Categories height={refHeight}>
                    <CategoryList>
                        {isLoading ?
                            <Loop />
                            :
                            menuProducts &&
                            Array.isArray(menuProducts) &&
                            menuProducts.map((itemLvl1) => {
                                return (
                                    <CategoryListItem
                                        key={itemLvl1.cid}
                                        hasMore={itemLvl1?.children?.length > 0}
                                        onClick={() =>
                                            handleSubCategorySelect(itemLvl1)
                                        }
                                    >
                                        <CategoryListItemLink
                                            isactive={
                                                itemCategory?.cid ===
                                                itemLvl1?.cid
                                            }
                                        >
                                            {itemLvl1.title} ({itemLvl1.count})
                                        </CategoryListItemLink>
                                        {itemLvl1?.children?.length > 0 ? (
                                            <CategorySubList
                                                isactive={
                                                    itemCategory?.cid ===
                                                    itemLvl1?.cid
                                                }
                                            >
                                                {itemLvl1?.children.map(
                                                    (itemLvl2) => {
                                                        return (
                                                            <CategorySubListItem
                                                                key={
                                                                    itemLvl1.cid +
                                                                    itemLvl2.gid
                                                                }
                                                                onClick={(e) =>
                                                                    handleSecondCategorySelect(
                                                                        e,
                                                                        itemLvl2
                                                                    )
                                                                }
                                                            >
                                                                <CategorySubListItemLink
                                                                    isactive={
                                                                        itemGroup?.gid ===
                                                                        itemLvl2?.gid
                                                                    }
                                                                >
                                                                    {
                                                                        itemLvl2.title
                                                                    }
                                                                    (
                                                                    {
                                                                        itemLvl2.count
                                                                    }
                                                                    )
                                                                </CategorySubListItemLink>

                                                                {itemLvl2
                                                                    ?.children
                                                                    ?.length >
                                                                    0 ? (
                                                                    <CategorySubList
                                                                        isactive={
                                                                            itemGroup?.gid ===
                                                                            itemLvl2?.gid
                                                                        }
                                                                    >
                                                                        {itemLvl2?.children.map(
                                                                            (
                                                                                itemLvl3
                                                                            ) => {
                                                                                return (
                                                                                    <CategorySubListItem
                                                                                        key={
                                                                                            itemLvl1.cid +
                                                                                            itemLvl2.gid +
                                                                                            itemLvl3.mid
                                                                                        }
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleThirdCategorySelect(
                                                                                                e,
                                                                                                itemLvl3
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <SubCategorySubListItemLink
                                                                                            isactive={
                                                                                                itemLvl3?.mid ===
                                                                                                itemModel?.mid
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                itemLvl3.title
                                                                                            }

                                                                                            (
                                                                                            {
                                                                                                itemLvl3.count
                                                                                            }

                                                                                            )
                                                                                        </SubCategorySubListItemLink>
                                                                                    </CategorySubListItem>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </CategorySubList>
                                                                ) : null}
                                                            </CategorySubListItem>
                                                        );
                                                    }
                                                )}
                                            </CategorySubList>
                                        ) : null}
                                    </CategoryListItem>
                                );
                            })}
                    </CategoryList>
                </Categories>
            </Wrapper>
        </>
    );
}
