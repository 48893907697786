import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";

import {
    FormLabel,
} from '../style'

const marginLeft = { marginLeft: '10px'}

export const OrderSummary = memo(({
  deliveryType = '',
  sourceLocation = '',
  comment,
  priceOfferReceiver,
}) => {
    const { t } = useTranslation();
    const { name: userName } = useSelector(
        (state) => state.global.userData
    );

    const DateNow = new Date().toLocaleString()

    return (
        <Box>
            <Box display="flex">
                <FormLabel>{t("productsList.date")}</FormLabel>
                <FormLabel style={marginLeft}>{DateNow}</FormLabel>
            </Box>
            <Box display="flex">
                <FormLabel>{t("productsList.user")}</FormLabel>
                <FormLabel style={marginLeft}>{userName}</FormLabel>
            </Box>
            <Box display="flex">
                <FormLabel>{t("productsList.deliveryType.title")}</FormLabel>
                <FormLabel style={marginLeft}>{deliveryType}</FormLabel>
            </Box>
            <Box display="flex">
                <FormLabel>{t("productsList.sourceLocation")}</FormLabel>
                <FormLabel style={marginLeft}>{sourceLocation}</FormLabel>
            </Box>
            <Box display="flex">
                <FormLabel>{t("productsList.commentsTitle")}</FormLabel>
                <FormLabel style={marginLeft}>{comment}</FormLabel>
            </Box>
            {priceOfferReceiver && (
                <>
                    <Box display="flex">
                        <FormLabel>{t("productsList.priceOfferTitle")}</FormLabel>
                        <FormLabel style={marginLeft}>{priceOfferReceiver}</FormLabel>
                    </Box>
                </>
            )}
        </Box>
    );
});
