import React from "react";
import { useDispatch } from "react-redux";

import { CartImage,   
        CartImageWrapper,
        CartInfoListItem,
        CartInfoTitle,
        StyledCard } from "../Carts/style";
import { useTranslation } from "react-i18next";
import { fetchProductDetails } from "../../../core/redux/asyncActions/global";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

export default function CartsItem({ data }) {
    
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleItemPopup = async(e, id) => {
        e.stopPropagation();
        await dispatch(fetchProductDetails(id, 1))
    };

    const price = (data?.prices && Boolean(data?.prices[0])) ? data.prices[0][1] : "";

    return (
        <StyledCard style={{cursor: "pointer"}} onClick={(e) => handleItemPopup(e, data?.id)}>
            <CardHeader 
                avatar={ 
                <CartImageWrapper>
                    <CartImage
                        src={data?.image
                             ? `${data?.image}`
                             : "/product-dot.svg"
                        }
                        alt={data?.name}
                    />
                </CartImageWrapper>}
                title={ <CartInfoTitle>{data?.description || data?.name}</CartInfoTitle>}

                subheader={
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <CartInfoListItem>
                            {t("productsList.tableHeader.AKCode")}: {data?.ak_code}
                            </CartInfoListItem>
                        </Grid>
                        <Grid item xs={6}>
                        <CartInfoListItem>{
                        t("productsList.tableHeader.oem")}: {data?.oem_code}
                        </CartInfoListItem></Grid>
                        <Grid item xs={6}>
                            <CartInfoListItem>
                            {t("productsList.tableHeader.price")}: {price}
                            </CartInfoListItem>
                        </Grid>
                    </Grid>
                    }
            />
        </StyledCard>
    );
}
