import React, { useRef, useEffect } from "react";
import { Box, Chip } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { StyledCircularProgress } from "../../CircularProgress";
import { CartWrapper, Arrow } from "../Carts/style";
import { SelectedCategoriesWrapper } from "../../Listing/style";
import {
    setSelectedTag,
} from "../../../core/redux/actions/global";
import { deleteTag, fetchTagItems } from "../../../core/redux/asyncActions/global";

export default function Tags({ overlayClick, isMobileScreen, anchorEl }) {
    const tagRef = useRef(null);
    const dispatch = useDispatch();

    const { tagsList, selectedTag } = useSelector(
        (state) => state.global);

    const handleClick = (e, id) => {
        if (id) {
            dispatch(setSelectedTag(id));
            dispatch(fetchTagItems(id))
            overlayClick();
        }
        if (
            tagRef.current &&
            !tagRef.current.contains(e.target) &&
            e.target !== document.body
        ) {
            overlayClick();
        }
    };

    const handleClickDelete = (e, id, title) => {
        dispatch(deleteTag(id, title));
        if (title === selectedTag) {
            dispatch(setSelectedTag(null));
        }
        if (
            tagRef.current &&
            !tagRef.current.contains(e.target) &&
            e.target !== document.body
        ) {
            overlayClick();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <CartWrapper style={{ height: "fit-content" }} ref={tagRef}>
            <Arrow left={anchorEl?.current} />
            <Box
                style={{
                    minHeight: "90px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    maxHeight: isMobileScreen ? "100vh" : "500px",
                    justifyContent: "center",
                    flexDirection: "row",
                    paddingTop: "8px"
                }}
            >
                {(tagsList?.isLoading) ?
                    <StyledCircularProgress disableShrink/> :
                    <SelectedCategoriesWrapper style={{ marginBottom: "10px" }}>
                        {tagsList?.results.map((tag) => (
                            <Chip
                                label={tag?.title}
                                onClick={(e) => handleClick(e, tag?.id, tag?.title)}
                                onDelete={(e) => handleClickDelete(e, tag?.id, tag?.title)}
                                key={tag?.id}
                                style={{ margin: "5px" }}
                                clickable={true}
                            />
                        ))
                        }
                    </SelectedCategoriesWrapper>
                }
            </Box>
        </CartWrapper>
    )
}
