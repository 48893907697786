import HomePage from "../../pages/Home";
import RegisterPage, { RegistrationSuccess } from "../../pages/Register";
import LoginPage from "../../pages/Login";
import PrivacyPolicyPage from "../../pages/PrivacyPolicy";
import PasswordResetPage from "../../pages/PasswordReset";
import { InvoicesPage } from "../../pages/Invoices";
import OemPage from "../../pages/Oem";
import OemItemPage from "../../pages/OemItem";
import { OrdersHistory } from "../../pages/OrdersHistory"
import { OrderHistoryItem } from "../../pages/OrderHistoryItem";
import { PriceOffersHistory } from "../../pages/PriceOfferHistory";
import { PriceOfferHistoryItem } from "../../pages/PriceOfferHistoryItem";
import PublicSearch from "../../pages/PublicSearch";
import { ROUTE_TYPES } from "../../pages/Login/components/AuthRoute";
import { Order } from "../../pages/Order";
import { ReturnItemForm } from "../../pages/ReturnItemForm";
import { ReturnItemItem } from "../../pages/ReturnItemItem";
import { ReturnItemList } from "../../pages/ReturnItemList";


export const HOME_PATH = "/home";
export const INVOICES_PATH = "/invoices";
export const OEM_ITEM_PATH = "/oem/:vin/:unitId/:catalog?";
export const OEM_PATH = "/oem/:vin?";
export const PLATE_SEARCH_ITEM_PATH = "/plate/:plate/:unitId";
export const PLATE_SEARCH_PATH = "/plate/:plate?";
export const PUBLIC_SEARCH_PATH = "/search";
export const ORDER_PATH = "/order/:cartId";
export const ORDER_HISTORY_ITEM_PATH = "/orders/history/:id";
export const ORDER_HISTORY_PATH = "/orders/history";
export const PRICE_OFFER_HISTORY_ITEM_PATH = "/price-offers/history/:id";
export const PRICE_OFFER_HISTORY_PATH = "/price-offers/history";
export const REGISTER_PATH = "/register";
export const REGISTER_SUCCESS_PATH = "/register-success";
export const PRIVACY_POLICY = "/privacy-policy";
export const PASSWORD_RESET = "/password-reset/:token?/:uid?";
export const PASSWORD_RESET_BASE = "/password-reset";
export const DEFAULT_PATH = "/";
export const ITEM_RETURN_FORM_PATH = "/return-item";
export const ITEM_RETURN_LIST_PATH = "/item-returns";
export const ITEM_RETURN_ITEM_PATH = "/item-returns-item/:id";

const routes = [
    {
        path: INVOICES_PATH,
        Component: InvoicesPage,
        type: ROUTE_TYPES.Private,
    },
    {
        path: ORDER_PATH,
        Component: Order,
        type: ROUTE_TYPES.Private,
    },
    {
        path: ORDER_HISTORY_ITEM_PATH,
        Component: OrderHistoryItem,
        type: ROUTE_TYPES.Private,
    },
    {
        path: ORDER_HISTORY_PATH,
        Component: OrdersHistory,
        type: ROUTE_TYPES.Private,
    },
    {
        path: PRICE_OFFER_HISTORY_ITEM_PATH,
        Component: PriceOfferHistoryItem,
        type: ROUTE_TYPES.Private,
    },
    {
        path: PRICE_OFFER_HISTORY_PATH,
        Component: PriceOffersHistory,
        type: ROUTE_TYPES.Private,
    },
    {
        path: ITEM_RETURN_FORM_PATH,
        Component: ReturnItemForm,
        type: ROUTE_TYPES.Private,
    },
    {
        path: ITEM_RETURN_LIST_PATH,
        Component: ReturnItemList,
        type: ROUTE_TYPES.Private,
    },
    {
        path: ITEM_RETURN_ITEM_PATH,
        Component: ReturnItemItem,
        type: ROUTE_TYPES.Private,
    },
    {
        path: OEM_ITEM_PATH,
        Component: OemItemPage,
        type: ROUTE_TYPES.Private,
    },
    {
        path: OEM_PATH,
        Component: OemPage,
        type: ROUTE_TYPES.Private,
    },
    {
        path: PLATE_SEARCH_ITEM_PATH,
        Component: OemItemPage,
        type: ROUTE_TYPES.Private,
    },
    {
        path: PLATE_SEARCH_PATH,
        Component: OemPage,
        type: ROUTE_TYPES.Private,
    },
    {
        path: HOME_PATH,
        Component: HomePage,
        type: ROUTE_TYPES.Private,
    },
    {
        path: PUBLIC_SEARCH_PATH,
        Component: PublicSearch,
        type: ROUTE_TYPES.Guest,
    },
    {
        path: REGISTER_PATH,
        Component: RegisterPage,
        type: ROUTE_TYPES.Guest,
    },
    {
        path: REGISTER_SUCCESS_PATH,
        Component: RegistrationSuccess,
        type: ROUTE_TYPES.Guest,
    },
    {
        path: PRIVACY_POLICY,
        Component: PrivacyPolicyPage,
        type: ROUTE_TYPES.Guest,
    },
    {
        path: PASSWORD_RESET,
        Component: PasswordResetPage,
        type: ROUTE_TYPES.Guest,
    },
    {
        path: DEFAULT_PATH,
        Component: LoginPage,
        type: ROUTE_TYPES.Guest,
    }
];

export default routes;
