import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../../../TableProducts";

const HistoryList = ({ purchases }) => {
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                id: "order_date",
                label: t("productsList.tablePurchaseHistoryHeader.date").toUpperCase(),
                valueGetter: "order_date",
            },
            {
                id: "count",
                label: t("productsList.tablePurchaseHistoryHeader.count").toUpperCase(),
                valueGetter: "count",
            },
            {
                id: "unit_price",
                label: t("productsList.tablePurchaseHistoryHeader.unitPrice").toUpperCase(),
                valueGetter: "unit_price",
            },
            {
                id: "tag_titles",
                label: t("productsList.tablePurchaseHistoryHeader.tags").toUpperCase(),
                valueGetter: "tag_titles",
            },
            {
                id: "user",
                label: t("productsList.tablePurchaseHistoryHeader.user").toUpperCase(),
                valueGetter: "user",
            },
        ]
    );

    return <Table items={purchases} columns={columns} clickable={false} />;
}; 

export default memo(HistoryList);
