import React, { useState, useEffect } from "react";
import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { COUNTRY_CURRENCY } from "../../../../core/utils";
import Typography from "../../../Typography";
import { ImageWithZoom } from "../ImageWithZoom";
import { ImageWithZoomCarousel } from "../ImageWithZoomCarousel";
import { Price } from "../Price";
import { Stock } from "../Stock";
import { Link, useHistory } from "react-router-dom";
import {
    CarouselWrapper,
    ItemCardWrapper,
    ItemSpec,
    TitleWrapper,
} from "./style";
import { StyledCarousel, Trail } from "../ItemDetailsModal/components/ItemDetailsHeader/style";
import QuantityButton from "../QuantityButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import {
    clearProductDetails,
    setSelectedItemCategory,
    setSelectedItemGroup,
    setSearchQuery,
    setSearchVisible,
    setSelectedItemModel,
    updateFormSubmissionStatus
} from "../../../../core/redux/actions/global";
import {
    fetchProductDetails,
    removeComment,
    removeTagItem
} from "../../../../core/redux/asyncActions/global";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { HOME_PATH } from "../../../../core/routes";
import api from "../../../../core/api";
import { MiniInput } from "../ItemDetailsModal/components/ItemDetailsHeader/MiniInput";
import { MiniButton } from "../ItemDetailsModal/components/ItemDetailsHeader/MiniButton";
import { Overlay } from "../MiniChangeMessage/style";
import { StyledCircularProgress } from "../../../CircularProgress";
import { MiniMessage } from "../MiniChangeMessage/MiniMessage";
import { SelectedCategoriesWrapper } from "../../style";


export const ItemCard = ({
    data,
    order,
    orderItem,
    images = [],
    showQuantity,
    showMinQuantity = true,
    isAvailability,
    handleItemPopupClick,
    orderPage,
    productDetails = false,
    setIsOverlayVisible,
    itemNo,
    priceOffer = false,
}) => {
    const {
        item_id,
        id,
        description: title,
        ak_code: akCode,
        oem_code: oem,
        vendor_item_code: order_oem,
        stock,
        vendor,
        vendor_image,
        prices,
        image,
        unit_price = '',
        autokada_code = '',
        name = '',
        availability_data = [],
        comment = null,
        category,
        group,
        model,
        tags
    } = { ...data };
    const dispatch = useDispatch();
    const history = useHistory();
    const [productUnits, setProductUnits] = useState(1);
    const [showComment, setShowComment] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [MiniChangeMessage, setMiniChangeMessage] = useState("");
    const [showMiniMessage, setShowMiniMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    const productUnitsCount = (unitsCount) => {
        setProductUnits(unitsCount);
    }

    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData
    );
    const { t } = useTranslation();

    const handleItemPopup = async (e) => {
        e.stopPropagation();
        dispatch(fetchProductDetails((id || item_id), 1))
    };

    const deleteComment = () => {
        dispatch(removeComment(id, title));
        setShowComment(false);
    }

    useEffect(() => {
        if (comment) {
            setShowComment(true);
        }
        if (tags?.length > 0) {
            setShowTags(true)
        }
    }, [comment]);

    const handleCategory = () => {
        dispatch(setSearchVisible(false));
        dispatch(setSearchQuery(""));
        dispatch(clearProductDetails());
        history.push(HOME_PATH);
        dispatch(setSelectedItemCategory(category));
    }

    const handleGroup = () => {
        handleCategory();
        dispatch(setSelectedItemGroup(group));
    }

    const handleModel = () => {
        handleGroup();
        dispatch(setSelectedItemModel(model));
    }

    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (event) => {
        const { value } = event.target;
        const regex = /^\d*\.?\d*$/; // Positive integer or positive decimal regex pattern

        if (regex.test(value) || value === "") {
        setInputValue(value);
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const minQuantity = event.target.elements.minQuantityValue.value;
        try {
            const body = new FormData();
            body.append("min_quantity", minQuantity);
            body.append("ak_code", akCode)
            const response = await api.post("/item-details", body, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            });

            const status = response.data.status
            const message = response.data.message
            if (response.data && status === "success") {
                setFormSuccess(true);
                setMiniChangeMessage(message)
                setShowMiniMessage(true);
                setIsOverlayVisible(true);
                dispatch(updateFormSubmissionStatus(true));
            } else if (response.data && status === "partial success") {
                setFormSuccess(true);
                setMiniChangeMessage(message)
                setShowMiniMessage(true);
                setIsOverlayVisible(true);
                dispatch(updateFormSubmissionStatus(true));
            } else if (response.data && status === "failure") {
                setFormSuccess(false);
                setMiniChangeMessage(message);
                setShowMiniMessage(true);
                setIsOverlayVisible(true);
            } else {
                console.error("Unexpected response");
            }
        } catch (error) {
            console.error('Error submitting min quantity change form:', error);
        } finally {
            setLoading(false);
        }
    };

    const salesperson = useSelector(
        (state) => state.global?.productDetails?.list?.salesperson
    );

    const minq = useSelector(
        (state) => state.global?.productDetails?.list?.min_quantity
    );

    const handleClickDelete = async (tag) => {
        if (tags.length === 1) {
            setShowTags(false);
        }
        setLoading(true);
        dispatch(removeTagItem(tag[0], tag[1], id, title));
        await dispatch(fetchProductDetails(id, 1));
        setLoading(false);
    };

    if (order && !priceOffer) {
        return (
            <ItemCardWrapper>
                <Box flex="1" display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        marginBottom="12px"
                    >
                        <Link to={`/orders/history/${order.number.slice(2)}`} style={{"text-decoration": "none"}}>
                            <TitleWrapper>{order.number}</TitleWrapper>
                        </Link>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.created"
                            )}: ${order.date_created}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.state"
                            )}: ${order.state_label}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.user"
                            )}: ${order.user}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.customer"
                            )}: ${order.customer || '-'}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.total"
                            )}: ${COUNTRY_CURRENCY[currentCountry]} ${order.total}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.sourceLocation"
                            )}: ${order.source_location_label_short}`}</ItemSpec>
                        </Grid>
                    </Grid>
                </Box>
            </ItemCardWrapper>
        );
    }

    if (order && priceOffer) {
        return (
            <ItemCardWrapper>
                <Box flex="1" display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        marginBottom="12px"
                    >
                        <Link to={`/price-offers/history/${order.number.slice(2)}`} style={{"text-decoration": "none"}}>
                            <TitleWrapper>{order.number}</TitleWrapper>
                        </Link>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.created"
                            )}: ${order.date_created}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.user"
                            )}: ${order.user}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.customer"
                            )}: ${order.customer || '-'}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.total"
                            )}: ${COUNTRY_CURRENCY[currentCountry]} ${order.total}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.offerReceiver"
                            )}: ${order.price_offer_receiver}`}</ItemSpec>
                        </Grid>
                    </Grid>
                </Box>
            </ItemCardWrapper>
        );
    }

    if (orderItem) {
        return (
            <ItemCardWrapper>
                <Box flex="1" display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        marginBottom="12px"
                    >
                        <TitleWrapper onClick={(e) => handleItemPopupClick(e, orderItem?.number)}>{orderItem?.description}</TitleWrapper>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.tableHeader.AKCode"
                            )}: ${orderItem?.autokada_code || '-'}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.vendorCode"
                            )}: ${orderItem?.vendor_item_code}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.tableHeader.vendor"
                            )}: `}
                                {(orderItem?.vendor_image) ?
                                    <img src={orderItem?.vendor_image} alt={orderItem?.vendor_name} style={{ height: "10px" }} /> :
                                    orderItem?.vendor_name}
                            </ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.description"
                            )}: ${orderItem.description}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.unitPrice"
                            )}: ${COUNTRY_CURRENCY[currentCountry]} ${orderItem.unit_price}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.count"
                            )}: ${orderItem.count}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.orderHistory.total"
                            )}: ${COUNTRY_CURRENCY[currentCountry]} ${orderItem.total}`}</ItemSpec>
                        </Grid>
                    </Grid>
                </Box>
            </ItemCardWrapper>
        );
    }

    return (
        <ItemCardWrapper autoDelivery={data?.is_stockmin}>
            {(category?.cid && productDetails) &&
                <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexFlow: "row nowrap", marginBottom: "10px" }}>
                    <Trail onClick={handleCategory}>
                        {category?.title}
                    </Trail>
                    {group?.gid && <>
                        <ArrowForwardIosIcon style={{ width: "8px", margin: "0 4px" }} />
                        <Trail onClick={handleGroup}>
                            {group?.title}
                        </Trail>
                        {model?.mid && <>
                            <ArrowForwardIosIcon style={{ width: "8px", margin: "0 4px" }} />
                            <Trail onClick={handleModel}>
                                {model?.title}
                            </Trail>
                        </>}
                    </>}
                </Box>
            }
            <Box display="flex" flexDirection="row">
                <Box
                    width="60px"
                    minHeight="48px"
                    maxHeight="100px"
                    height="50%"
                    marginRight="5px"
                >
                    {images.length === 0 && (
                        <ImageWithZoom
                            src={
                                image
                                    ? `${image}`
                                    : "/product-dot.svg"
                            }
                            popupSize={{ width: "100%", height: "100%" }}
                            imgSize={image && { imgWidth: "50px", imgHeight: "50px", width: "50px" }}
                        />
                    )}
                    <CarouselWrapper width="60px">
                        {Boolean(images.length) && (
                            <StyledCarousel
                                indicators={false}
                                autoPlay={false}
                                navButtonsAlwaysVisible
                                navButtonsAlwaysInvisible={images.length <= 1}
                            >
                                {images.map(({ url }) => (
                                    <ImageWithZoomCarousel
                                        key={url}
                                        src={
                                            url
                                                ? `${url}`
                                                : "/product-dot.svg"
                                        }
                                        placement={"top"}
                                        currentPhotos={images}
                                        marginTop={-194}
                                        width={200}
                                        popupSize={{
                                            width: "200px",
                                            height: "200px",
                                        }}
                                    />
                                ))}
                            </StyledCarousel>
                        )}
                    </CarouselWrapper>
                </Box>
                <Box flex="1" display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        marginBottom="12px"
                    >

                        <TitleWrapper onClick={(e) => handleItemPopup(e)}>{title}</TitleWrapper>
                        <Box display="flex" flexDirection="row">
                            <Typography
                                color="#37474F"
                                customVariant="body"
                                fontWeight={700}
                            >
                                {COUNTRY_CURRENCY[currentCountry]}
                            </Typography>
                            <Price price={unit_price || prices} productUnits={productUnits} orderPage={orderPage} />
                        </Box>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.tableHeader.AKCode"
                            )}: ${autokada_code || akCode}`}</ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.tableHeader.vendor"
                            )}: `}
                                {(vendor_image) ?
                                    <img src={vendor_image} alt={name || vendor} style={{ height: "10px" }} /> :
                                    vendor || name
                                }
                            </ItemSpec>
                        </Grid>
                        <Grid item xs={6}>
                            <ItemSpec>{`${t(
                                "productsList.tableHeader.oem"
                            )}: ${order_oem || oem}`}</ItemSpec>
                        </Grid>
                        {stock && (
                            <Grid item xs={6}>
                                <Box display="flex" flexDirection="row">
                                    <ItemSpec>
                                        {t("productsList.tableHeader.stock")}:
                                    </ItemSpec>
                                    <Stock stock={stock} id={id} />
                                </Box>
                            </Grid>
                        )}
                        {showMinQuantity && (
                            <>
                            {salesperson && (
                                <Grid item xs={6} style={{ paddingTop: "2px" }}>
                                    <Box display="flex" flexDirection="row">
                                        <form onSubmit={handleFormSubmit}>
                                            <Box display="flex">
                                                <Typography
                                                    customVariant="body"
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "200",
                                                    }}
                                                >
                                                    MINI:&nbsp;
                                                </Typography>
                                                <MiniInput
                                                    inputValue={inputValue}
                                                    minq={minq}
                                                    handleInputChange={handleInputChange}
                                                    style={{
                                                        width: "20px",
                                                        padding: "1px"
                                                    }}
                                                />
                                                <MiniButton
                                                    inputValue={inputValue}
                                                    buttonText="CHANGE"
                                                    style={{
                                                        marginLeft: "2px",
                                                        fontSize: "12px",
                                                        padding: "0px 5px"
                                                    }}
                                                />
                                            </Box>
                                        </form>
                                    </Box>
                                </Grid>
                            )}
                            </>
                        )}
                        
                        <Grid item xs={6}>
                            {isAvailability && (
                                <ItemSpec>
                                    <Box display="flex" flexDirection="row">
                                        {t("productsList.tableHeader.availability")}:
                                        <Box display="flex" flexDirection="column" style={{ color: "#F28A22" }}>
                                            {(availability_data?.length === 0) ? "-" :
                                                availability_data.map((data, i) => (
                                                    <div key={i}>
                                                        {" " + data.row_value}
                                                    </div>
                                                ))}
                                        </Box>
                                    </Box>
                                </ItemSpec>
                            )}
                        </Grid>
                        {(showComment && productDetails) &&
                            <Grid item xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <ItemSpec>{`${t(
                                    "productsList.tableHeader.comment"
                                )}: ${comment}`}</ItemSpec>
                                <DeleteOutlinedIcon onClick={deleteComment} fontSize="small" />
                            </Grid>
                        }
                        {(showTags && productDetails) &&
                            <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                                <ItemSpec>{`${t("productsList.tableHeader.tags")}:`}</ItemSpec>
                                <SelectedCategoriesWrapper type="vendor">
                                    {tags?.map((tag) => (
                                        <Chip
                                            size="small"
                                            label={tag[1]}
                                            onDelete={() => handleClickDelete(tag)}
                                            key={tag[0]}
                                            style={{
                                                marginRight: "8px",
                                                marginTop: "8px"
                                            }}
                                            clickable={false}
                                        />
                                    ))
                                    }
                                </SelectedCategoriesWrapper>
                            </Grid>
                        }
                    </Grid>
                    {showQuantity &&
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            marginTop="12px"
                        >   
                            <QuantityButton
                                data={data}
                                productUnitsCount={productUnitsCount}
                                itemAkCode={data.ak_code}
                                itemNo={itemNo}
                            />
                        </Box>
                    }
                </Box>
            </Box>
            {showMiniMessage && (
                <div>
                    <MiniMessage
                        message={MiniChangeMessage}
                        fontColor={formSuccess ? "green" : "#f28a22"}
                        onClose={async() => {
                            setShowMiniMessage(false);
                            setLoading(true);
                            await dispatch(fetchProductDetails(id, 1));
                            setLoading(false);
                            setIsOverlayVisible(false);
                            setInputValue("");

                        }}
                    />
                    <Overlay show={true} />
                </div>
                
            )}
            {loading && (
                <>
                    {setIsOverlayVisible(true)}
                    <Overlay show={true} />
                </>
            )}
            {loading && (
                <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="center"
                    position="absolute"
                    width="100%"
                    height="100%"
                    background="rgba(255, 255, 255, 0.7)"
                >
                    <StyledCircularProgress />
                </Box>
            )}
        </ItemCardWrapper>
    );
};
